<template>
  <v-card
    :style="`background-color: ${containerColor}`"
    :flat="!searchBarWithBorder"
    :loading="loading"
    tile
    class="z-index-1"
  >
    <!-- <v-toolbar  color="secondary" height="auto" elavation="0" :flat="true"> -->
    <v-container
      class="toolbar-container"
      :style="`padding-bottom: ${
        showCRlogo ? '52px' : '10px'
      }; background-color: ${containerColor}`"
    >
      <v-row justify="center">
        <v-col
          :cols="
            $vuetify.breakpoint.smAndDown || forceVerticalDisplayMode ? 12 : 0
          "
        >
          <GeocoderSearch
            :style="colorOfInputElementsStyle"
            v-model="params.origin"
            :inputKey="originInputKey"
            :pageLoading="pageLoading.value"
            :geocodeInputChanged="geocodeInputChanged"
            label="Start-Adresse"
            placeholder="Straße, Ort, PLZ"
            :allowGeolocation="true"
            :menuProps="embedPositioningHack ? { maxHeight: 150 } : undefined"
          />
        </v-col>

        <v-col
          :cols="
            $vuetify.breakpoint.smAndDown || forceVerticalDisplayMode ? 12 : 0
          "
          v-if="showDestination"
        >
          <GeocoderSearch
            :style="colorOfInputElementsStyle"
            v-model="params.destination"
            :inputKey="destinationInputKey"
            :pageLoading="pageLoading.value"
            :geocodeInputChanged="geocodeInputChanged"
            :pois="destinations"
            :allowGeocoding="allowGeocoding"
            :focusPoint="destinationFilters.geocoder.centerPoint"
            :limitFocusDist="destinationFilters.geocoder.radiusKm"
            :menuProps="embedPositioningHack ? { maxHeight: 150 } : undefined"
            label="Ziel-Adresse"
            placeholder="Straße, Ort, PLZ"
          />
        </v-col>

        <v-col
          :cols="
            $vuetify.breakpoint.smAndDown || forceVerticalDisplayMode ? 12 : 0
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
            "
          >
            <DateTimePicker
              :colorOfInputElementsStyle="colorOfInputElementsStyle"
              v-model="params.arrival"
              :pageLoading="pageLoading.value"
              dateFormat="dd.MM.yyyy"
              :label="destTimeOrientationLabel"
              :textFieldProps="{
                outlined: false,
                prependInnerIcon: showIcons ? 'event' : '',
                readonly: true,
                hideDetails: true,
              }"
              :datePickerProps="datePickerConf"
              :timePickerProps="{
                color: $vuetify.theme.isDark
                  ? 'secondary'
                  : 'primary lighten-2',
              }"
              :key="showIcons"
              :embedPositioningHack="embedPositioningHack"
            />
            <div
              style="
                margin-left: 3px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="center-div-row">
                <input
                  type="radio"
                  id="radio-ab"
                  value="false"
                  @change="journeyThereTimeOrientationValueChanged('departure')"
                  :checked="
                    journeyThereTimeOrientationValueChecked === 'departure'
                  "
                />
                <label for="radio-ab" class="margin-right-3px">Ab</label>
              </div>
              <div class="center-div-row">
                <input
                  type="radio"
                  id="radio-an"
                  value="true"
                  @change="journeyThereTimeOrientationValueChanged('arrival')"
                  :checked="
                    journeyThereTimeOrientationValueChecked === 'arrival'
                  "
                />
                <label for="radio-an">An</label>
              </div>
            </div>
          </div>
        </v-col>

        <v-col
          :cols="
            $vuetify.breakpoint.smAndDown || forceVerticalDisplayMode ? 12 : 0
          "
        >
          <DateTimePicker
            :colorOfInputElementsStyle="colorOfInputElementsStyle"
            v-model="params.departure"
            :pageLoading="pageLoading.value"
            dateFormat="dd.MM.yyyy"
            label="Abfahrt — Rückfahrt"
            :textFieldProps="{
              'backgound-color': 'red',
              outlined: false,
              prependInnerIcon: showIcons ? 'event' : '',
              readonly: true,
              hideDetails: true,
            }"
            :datePickerProps="{
              color: $vuetify.theme.isDark ? 'secondary' : 'primary lighten-2',
              min: params.arrival ? params.arrival.toISOString() : undefined,
              max: maxDate,
            }"
            :timePickerProps="{
              color: $vuetify.theme.isDark ? 'secondary' : 'primary lighten-2',
            }"
            :key="showIcons"
            :embedPositioningHack="embedPositioningHack"
          />
        </v-col>

        <v-col
          :cols="
            $vuetify.breakpoint.smAndDown || forceVerticalDisplayMode ? 12 : 0
          "
          class="flex-md-grow-0 text-center d-print-none"
        >
          <!-- <div class="center-aligned-column-box full-height-width" >-->

          <v-btn
            @click="submitQuery(true)"
            :disabled="isNotValid || loading"
            height="56px"
            block
            :color="$vuetify.theme.isDark ? 'blue-grey darken-2' : 'primary'"
          >
            <v-icon large>search</v-icon>
            <span class="d-md-none d-xl-inline"
              >Verkehrsmittel vergleichen</span
            >
          </v-btn>
        </v-col>
        <v-col
          :cols="
            $vuetify.breakpoint.smAndDown || forceVerticalDisplayMode ? 12 : 0
          "
          class="text-center d-print-none"
        >
          <div class="left-aligned-column-box">
            <div class="text-align-left">
              <v-icon class="margin-right-3px mr-1">format_line_spacing</v-icon>
              <label>Sortieren nach:</label>
            </div>
            <div class="left-aligned-2-col-grid margin-left-10px">
              <div class="center-div-row">
                <input
                  class="margin-right-3px"
                  type="radio"
                  id="radio-zeit"
                  value="true"
                  @change="sortByValueChanged('time')"
                  :checked="sortByValueChecked === 'time'"
                />
                <label for="radio-zeit" class="margin-right-3px">Zeit</label>
                <v-icon aria-hidden="false"> timer </v-icon>
              </div>
              <div class="center-div-row">
                <input
                  class="margin-right-3px"
                  type="radio"
                  id="radio-kosten"
                  value="false"
                  @change="sortByValueChanged('cost')"
                  :checked="sortByValueChecked === 'cost'"
                />
                <label for="radio-kosten" class="margin-right-3px"
                  >Kosten</label
                >
                <v-icon aria-hidden="false"> euro_symbol </v-icon>
              </div>
              <div class="center-div-row">
                <input
                  class="margin-right-3px"
                  type="radio"
                  id="radio-co2"
                  value="false"
                  @change="sortByValueChanged('co2')"
                  :checked="sortByValueChecked === 'co2'"
                />
                <label for="radio-co2" class="margin-right-3px">CO₂</label>
                <v-icon aria-hidden="false"> filter_drama </v-icon>
              </div>
              <div class="center-div-row">
                <input
                  class="margin-right-3px"
                  type="radio"
                  id="radio-sport"
                  value="false"
                  @change="sortByValueChanged('sport')"
                  :checked="sortByValueChecked === 'sport'"
                />
                <label for="radio-sport" class="margin-right-3px"
                  >Bewegung</label
                >
                <v-icon aria-hidden="false"> directions_run </v-icon>
              </div>
            </div>
          </div>
        </v-col>
        <!-- </div> -->
        <!-- </v-col> -->
      </v-row>
      <v-divider
        v-if="$vuetify.breakpoint.smAndDown || forceVerticalDisplayMode"
        class="margin-top-5px margin-bottom-5px"
      ></v-divider>
      <v-row no-gutters justify="space-between" align="start">
        <v-col
          :cols="
            $vuetify.breakpoint.smAndDown || forceVerticalDisplayMode ? 12 : 6
          "
        >
          <v-switch
            style="margin-top: 0px !important"
            id="include-intermodal-switch"
            :value="includeIntermodal"
            @change="includeIntermodalChanged()"
            flat
            :label="switchIncludeIntermodalLabel"
          ></v-switch>
        </v-col>
        <v-col
          class="flex-box-right-aligned-horizontal"
          :cols="
            $vuetify.breakpoint.smAndDown || forceVerticalDisplayMode ? 12 : 6
          "
        >
          <v-expansion-panels
            class="margin-right-5px margin-top-5px"
            :style="[widthOfPanel]"
            v-model="indexesOfOpenedPanels"
            :disabled="loading"
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header :style="colorOfInputElementsStyle"
                >Stau- und Parksuchzeit</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-subheader
                  >Aufschlag für Parksuchzeit und Zielerreichung in
                  Minuten</v-subheader
                >
                <v-slider
                  :value="parkingIndex"
                  @change="parkingValueChanged"
                  step="1"
                  :max="parkingTicksLabels.length - 1"
                  :tick-labels="parkingTicksLabels"
                  ticks="always"
                  tick-size="4"
                ></v-slider>
                <v-subheader
                  >Verzögerung aufgrund Verkehrsaufkommen in
                  Minuten</v-subheader
                >
                <v-slider
                  :value="congestionIndex"
                  @change="congestionValueChanged"
                  step="1"
                  :max="congestionTicksLabels.length - 1"
                  :tick-labels="congestionTicksLabels"
                  ticks="always"
                  tick-size="4"
                ></v-slider>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <!-- </v-toolbar> -->
    <div
      v-if="showCRlogo"
      class="margin-left-5px position-bottom-absolute"
      style="height: 50px; margin-bottom: 5px"
    >
      <img src="cleverroute-logo-white.png" class="full-height" />
    </div>
    <v-progress-linear v-if="loading" height="6" indeterminate reverse />
  </v-card>
</template>

<script>
import Vue from "vue";
import add from "date-fns/add";
import DateTimePicker from "./DateTimePicker.vue";
import GeocoderSearch from "./GeocoderSearch.vue";
import { fetchRouteParamsValid } from "../api";
import { GeocoderInputKeys } from "../models/constants-transports";
import { useConfigStore } from "../store/config-store";
import { storeToRefs } from "pinia";
import * as utils from "../utils";

export default Vue.extend({
  name: "Searchbar",
  components: {
    GeocoderSearch,
    DateTimePicker,
  },

  props: {
    config: { type: Object, default: undefined },
    showCRlogo: { type: Boolean, default: false },
    forceVerticalDisplayMode: { type: Boolean, default: false },
    // events to update values for congestion, parking, sorting-order and intermodal in the parent component
    updateMinutesCongestion: { type: Function },
    updateMinutesParking: { type: Function },
    updateIncludeIntermodal: { type: Function },
    locInputChanged: { type: Function },
    loading: { type: Boolean },
    params: {
      type: Object,
      default: () => ({
        origin: "",
        departure: "", // contains Date instances when filled out
        arrival: "",
        destination: "",
        includeIntermodal: undefined,
        minutesCongestion: "0",
        minutesParking: "0",
      }),
    },

    destinations: { type: Array, default: () => [] },
    queryHandler: { type: Function, default: console.log },
    queryOnInput: { type: Boolean, default: false },
    hideDestination: { type: Boolean, default: false },
    embedPositioningHack: { type: Boolean },
    extendedOptionsPanelOpened: { type: Function, default: (opened) => {} },
    searchBarWithBorder: { type: Boolean, default: true },
  },

  data: () => ({
    pageLoading: { value: false },
    originInputKey: GeocoderInputKeys.GeocoderOriginInput,
    destinationInputKey: GeocoderInputKeys.GeocoderDestinationInput,
    indexesOfOpenedPanels: [],
    show: true,
    // picked: "kosten",
    parkingTicksLabels: ["0", "1", "2", "3", "4", "5", "10", "15"],
    congestionTicksLabels: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "10",
      "20",
      "30",
      "40",
      "50",
      "60",
    ],
    metricToSortRoutesBy: { value: "time" },
    metricJourneyThereTimeOrientation: { value: "arrival" },
    switchIncludeIntermodalLabel: "inkl. Kombinationen (z.B. ÖPNV + Fahrrad (Park&Bike))",
    checkbox: false,
    loading2: false,
    destinationFilters: {
      types: [],
      geocoder: { centerPoint: null, radiusKm: null },
    },
    allowPastDate: false,
  }),
  watch: {
    //     'params.origin': {handler: function(n,o) {
    //       // console.log("params changed=",n,o)
    //     },deep: true,
    //      immediate: true
    //  }
  },
  mounted() {
    // load pageLoading variable from store to request status of page loading
    this.pageLoading = storeToRefs(useConfigStore()).pageLoading;
    this.metricToSortRoutesBy = storeToRefs(
      useConfigStore()
    ).metricToSortRoutesBy;

    this.allowPastDate = storeToRefs(useConfigStore()).allowPastDate;

    this.metricJourneyThereTimeOrientation = storeToRefs(
      useConfigStore()
    ).metricToJourneyThereTimeOrientation;
  },
  computed: {
    datePickerConf() {
      let conf = {
        color: this.$vuetify.theme.isDark ? "secondary" : "primary lighten-2",
        max: this.maxDate,
      };
      if (!this.allowPastDate.value) {
        conf = { ...conf, min: new Date().toISOString() };
      }
      return conf;
    },
    containerColor() {
      return this.$vuetify.theme.themes.light.secondary;
    },
    colorOfInputElementsStyle() {
      return utils.colorOfInputElementsStyle.bind(this)();
    },

    widthOfPanel() {
      if (this.indexesOfOpenedPanels.length === 0) {
        return { width: "220px" };
      } else {
        return { width: "400px" };
      }
    },
    isNotValid() {
      return !fetchRouteParamsValid(this.params, this.destinations);
    },
    showDestination() {
      return (
        !this.hideDestination &&
        (this.isMultiDestination || this.allowGeocoding)
      );
    },
    isMultiDestination() {
      return this.destinations.length > 1;
    },
    allowGeocoding() {
      const { types } = this.destinationFilters;
      return !types || types.includes("geocoder");
    },
    showIcons() {
      const { md } = this.$vuetify.breakpoint;
      return !(this.showDestination && md);
    },
    maxDate() {
      return add(new Date(), { days: 400 }).toISOString();
    },
    // binding of intermodal value to switch-button
    includeIntermodal: {
      get: function () {
        if (this.params.includeIntermodal !== undefined) {
          return this.params.includeIntermodal
            ? this.params.includeIntermodal
            : false;
        } else if (this.config?.frontend?.includeIntermodal !== undefined) {
          return this.config.frontend.includeIntermodal;
        } else {
          return false;
        }
      },
    },
    // binding of congestion-index (within labels-array) to slider
    congestionIndex: {
      get: function () {
        if (this.params.minutesCongestion !== undefined) {
          return this.params.minutesCongestion
            ? this.congestionTicksLabels.findIndex(
                (val) => val === this.params.minutesCongestion
              )
            : 0;
        } else if (
          this.config?.frontend?.minutesCongestion !== undefined &&
          this.congestionTicksLabels.findIndex(
            (val) => val === this.config.frontend.minutesCongestion
          )
        ) {
          return this.congestionTicksLabels.findIndex(
            (val) => val === this.config.frontend.minutesCongestion
          );
        } else {
          return 0;
        }
      },
    },
    // binding of parking-index (within labels-array) to slider
    parkingIndex: {
      get: function () {
        if (this.params.minutesParking !== undefined) {
          return this.params.minutesParking
            ? this.parkingTicksLabels.findIndex(
                (val) => val === this.params.minutesParking
              )
            : 0;
        } else if (
          this.config?.frontend?.minutesParking !== undefined &&
          this.parkingTicksLabels.findIndex(
            (val) => val === this.config.frontend.minutesParking
          )
        ) {
          return this.parkingTicksLabels.findIndex(
            (val) => val === this.config.frontend.minutesParking
          );
        } else {
          return 0;
        }
      },
    },
    // binding of sorting value to radio-buttons
    sortByValueChecked: {
      get: function () {
        return this.metricToSortRoutesBy.value;
      },
    },
    journeyThereTimeOrientationValueChecked: {
      get: function () {
        return this.metricJourneyThereTimeOrientation.value;
      },
    },
    destTimeOrientationLabel: {
      get: function () {
        return this.metricJourneyThereTimeOrientation.value == "arrival"
          ? "Ankunft — Hinfahrt"
          : "Abfahrt — Hinfahrt";
      },
    },
  },

  async created() {
    if (!this.config) {
      return;
    }
    this.destinationFilters = this.config.destinationFilters || {};
    if (!this.destinationFilters.geocoder)
      this.destinationFilters.geocoder = {};
    this.thirdpartyKeys = this.config.thirdparty;
  },
  watch: {
    /* to update the height of the widget, depending on whether the extended options panel is opened or closed,
    the status has to be emitted to the widget */
    indexesOfOpenedPanels() {
      const opened = this.indexesOfOpenedPanels.length > 0 ? true : false;
      if (this.extendedOptionsPanelOpened) {
        this.extendedOptionsPanelOpened(opened);
      }
    },
  },
  methods: {
    /* event triggered if different value is chosen in the geocode address field and which triggeres event
    to parent again  */
    geocodeInputChanged(geocodeObj, inputKey) {
      this.locInputChanged(geocodeObj, inputKey);
    },
    includeIntermodalChanged() {
      this.updateIncludeIntermodal(!this.includeIntermodal);
    },
    congestionValueChanged(index) {
      this.updateMinutesCongestion(this.congestionTicksLabels[index]);
    },
    parkingValueChanged(index) {
      this.updateMinutesParking(this.parkingTicksLabels[index]);
    },
    sortByValueChanged(sortByValue) {
      useConfigStore().setMetricToSortRoutesBy(sortByValue);
    },
    journeyThereTimeOrientationValueChanged(timeOrientation) {
      useConfigStore().setMetricToJourneyThereTimeOrientation(timeOrientation);
    },
    async submitQuery(force = false) {
      // window._paq.push(['trackEvent', 'routing', 'anfrage']);
      if (!force && !this.queryOnInput) return;
      if (this.isNotValid) return;
      this.loading2 = true;
      this.indexesOfOpenedPanels = [];

      await this.queryHandler(this.params);
      this.loading2 = false;
    },
  },
});
</script>
<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-box-right-aligned-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.justify-content-end {
  justify-content: end;
}

.margin-top-5px {
  margin-top: 5px;
}
.margin-right-5px {
  margin-right: 5px;
}
margin-bottom-5px {
  margin-bottom: 5px;
}
.center-div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.left-aligned-column-box {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
}

.center-aligned-column-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.left-aligned-2-col-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: flex-start;
}
.margin-left-10px {
  margin-left: 10px;
}

.margin-right-3px {
  margin-right: 3px;
}
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.text-align-left {
  text-align: left;
}

.toolbar-container {
  max-width: 100% !important;
}
.margin-top-5px {
  margin-top: 5px;
}

.margin-right-5px {
  margin-right: "5px";
}
.margin-bottom-5px {
  margin-bottom: 5px;
}
.z-index-1 {
  z-index: 1;
}
</style>
