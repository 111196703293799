import { decode } from "ngeohash";
import { GeocoderInputKeys, TransportTypesArray } from "./models/constants-transports";
import { useConfigStore } from "./store/config-store";
import { usePollStore } from "./store/poll-store";

export function formatDuration(minutes) {
  const min = Math.round(minutes) // round beforehand to avoid 2h 60m
  const h = Math.floor(min / 60)
  const m = min % 60
  if (h)
    return m ? `${h}h ${m}min` : `${h}h`
  else
    return `${m} min`
}

export function formatTime(timestamp) {
  // NOTE: this will show in the users timezone, which may be incorrect
  // ..due to resistFingerprinting
  return new Date(timestamp).toLocaleTimeString('de').substring(0, 5)
}


export function getInitCheckedTransports() {
  let checkedDictLocal = {};
  TransportTypesArray.forEach((tType) => (checkedDictLocal[tType] = false));
  return checkedDictLocal;
}

export function getInitCheckedProviders(config) {
  let checkedDictLocal = {};
  if (!config?.transports) {
    return {};
  }
  const filteredTransports = config?.transports?.filter(
    (t) =>
      t.transportType === "carsharing" || t.transportType === "bikesharing" || t.transportType === "pendlerportal"
  );
  const providerNames = filteredTransports.map((t) => t.ui.providerName);
  providerNames.forEach((tType) => (checkedDictLocal[tType] = false));
  return checkedDictLocal;
}

export function locInputChangedExternal(geocodeObj, inputKey) {
  // check whether origin or destination address field was modified and update it
  if (inputKey === GeocoderInputKeys.GeocoderOriginInput) {
    // assign coordinates and name if given
    if (geocodeObj.lonLat && geocodeObj.lonLat.length === 2) {
      // build query-parameter for coordinate in format "[lon],[lat]"
      this.searchParams.originLonLat =
        geocodeObj.lonLat[0] + "," + geocodeObj.lonLat[1];
    } else {
      this.searchParams.originLonLat = "";
    }
    if (geocodeObj.name) {
      this.searchParams.origin = geocodeObj.name;
    }
  } else if (inputKey === GeocoderInputKeys.GeocoderDestinationInput) {
    // if ID, Coordinates and destination name are passed then assign it, otherwise delete them from searchParams
    if (geocodeObj.id) {
      this.searchParams.destinationId = geocodeObj.id;
    } else {
      delete this.searchParams.destinationId;
    }
    if (geocodeObj.lonLat) {
      this.searchParams.destinationLonLat =
        geocodeObj.lonLat[0] + "," + geocodeObj.lonLat[1];
    } else {
      this.searchParams.destinationLonLat = "";
    }
    if (geocodeObj.name) {
      this.searchParams.destination = geocodeObj.name;
    }
    else {
      delete this.searchParams.destination;
    }
  }
}

/* parses the url param called like the locality informations and extended settings */
export function parseURLParams(p) {
  // get the requested Destination from the list of destinations to retrieve the destination name
  let config = this.config;
  let destinations = config.destinations;
  // if coordinates are given for the origin then pass it as parameter, otherwise pass the address
  if (p.originLonLat) {
    this.searchParams.originLonLat = p.originLonLat;
  }
  if (p.origin) {
    this.searchParams.origin = p.origin;
  }
  // if destinationId is given then pass it as queryparam, otherwise check if coordinates are given and if not then pass the address
  if (p.destinationId) {
    // assign ID of destination to the searchParams
    this.searchParams.destinationId = p.destinationId;

    // if destination name can be retrieved by the ID then assign it
    const destName = getDestinationNameByDestinationId(p.destination, destinations);
    if (destName) {
      this.searchParams.destination = destName;
    } else {
      this.searchParams.destination = p.destination;
    }

  }
  if (p.destinationLonLat) {
    this.searchParams.destinationLonLat = p.destinationLonLat;
  }
  if (p.destination) {
    // if destination name can be retrieved by the ID then assign it
    const destName = getDestinationNameByDestinationId(p.destination, destinations);
    if (destName) {
      this.searchParams.destination = destName;
    } else {
      this.searchParams.destination = p.destination;
    }
  }


  this.searchParams.arrival = p.arrival ? new Date(p.arrival) : "";
  this.searchParams.departure = p.departure ? new Date(p.departure) : "";
  this.searchParams.dayTicket = !!p.dayTicket;
  if (typeof (p.includeIntermodal) === "string") {
    if (p.includeIntermodal === "true") {
      this.searchParams.includeIntermodal = true;
    } else {
      this.searchParams.includeIntermodal = false;
    }
  } else if (this.config?.frontend?.includeIntermodal !== undefined) {
    return this.config.frontend.includeIntermodal;
  } else {
    return false;
  }
  this.searchParams.useDepartureTimeForJourneyThere = p.useDepartureTimeForJourneyThere !== undefined ? p.useDepartureTimeForJourneyThere
    : false; // set parameter for journey there time orientation e.g. arrival or departure time

  this.searchParams.minutesCongestion =
    p.minutesCongestion !== undefined
      ? p.minutesCongestion
      : this.searchParams.minutesCongestion;
  this.searchParams.minutesParking =
    p.minutesParking !== undefined
      ? p.minutesParking
      : this.searchParams.minutesParking;

}


/**
 * get full destination name by destination ID using an array of destinations' objects
 * 
 * @param {*} destId id like Rh hom
 * @param {*} destinations of type [{id: Rh hom, name: Rh. Homberg, lonLat: ...}]
 * @returns destination full name
 */
function getDestinationNameByDestinationId(destId, destinations) {
  let destName;
  // convert destId to lower case to consider typings
  destId = String(destId).toLowerCase()
  let dest = destinations.find(dest => (dest.id ? String(dest.id).toLowerCase() : dest.id) === destId);
  if (dest?.name) {
    destName = dest.name;
  }
  return destName;

}

/* applying some configs for the respective customer's instance */
export function applyConfig() {
  
  const {
    frontend
    // thirdparty: {
    //   openrouteservice: { apikeys },
    // }
  } = this.config;

  const {
    
      colors,
      includeIntermodal,
      minutesCongestion,
      minutesParking,
      poll,
      allowPastDate,
    
    // thirdparty: {
    //   openrouteservice: { apikeys },
    // }
  } = frontend;

  const apikeys = [];
  // pass include-Intermodal, ongestion and Parking values as query-params to backend
  this.searchParams.includeIntermodal = includeIntermodal
    ? true
    : false;

  this.searchParams.minutesCongestion = minutesCongestion ? minutesCongestion : 0;
  this.searchParams.minutesParking = minutesParking ? minutesParking : 0;

  if (colors) {
    this.$vuetify.theme.themes.light.primary = colors.primary;
    this.$vuetify.theme.themes.light.secondary = colors.secondary;
  }

  const store = useConfigStore();
  const { setOrsApiKeys, setMetricToSortRoutesBy, setAllowPastDate, setFrontendConfig } = store;
  setOrsApiKeys(apikeys);
  setFrontendConfig(frontend);
  setAllowPastDate(allowPastDate);


  const pollStore = usePollStore();
  pollStore.setGeneralPollSettings(poll.generalSettings);
  pollStore.setUsePoll(poll.usePoll);
  pollStore.setFrequencyType(poll.frequencyType);
  pollStore.setClientName(poll.clientName);
  pollStore.setPollType(poll.pollType);
  pollStore.setPollDate(poll.pollDate);
  pollStore.setTitle(poll.title);
  pollStore.setExternalPollLink(poll.externalPollLink);
  pollStore.setUseExternalPollLink(poll.useExternalPollLink);
  pollStore.setAlternativeExternalPollLink(poll.alternativeExternalPollLink);
  pollStore.setUseAlternativeExternalPollLink(poll.useAlternativeExternalPollLink);
  pollStore.setIntroductionSteps(poll.introductionSteps);
  pollStore.setIntroductionText(poll.introductionText);
  pollStore.setIntroductionTitle(poll.introductionTitle);
  console.log("poll.showCustomerLogo=",poll)
  pollStore.setShowCustomerLogo(poll.showCustomerLogo);


}

export function parseDestinationByDestinationName() {
  /* if a predefined destination is passed in URL then retrieve its coordinates by its name so the marker
       can be set in the map automatically on loading */
  if (this.searchParams.destination) {
    const dest = this.config.destinations.find(
      (dest) => dest.name === this.searchParams.destination
    );

    let lonLat;
    if (dest?.geo) {
      lonLat = decode(dest.geo);
      lonLat = [lonLat.longitude, lonLat.latitude];
      this.locInputChanged(
        { id: dest.id, name: dest.name, lonLat: lonLat },
        GeocoderInputKeys.GeocoderDestinationInput
      );
    }
  }
}

export function colorOfInputElementsStyle() {
  return { "background-color": colorOfInputElementsValue.bind(this)() };
}

export function colorOfInputElementsValue() {
  if (this.config?.frontend?.colorOfInputElements) {
    return this.config.frontend.colorOfInputElements;
  } else {
    return this.$vuetify.theme.themes.light.secondary;
  }
}

export function formatCo2ByValue(co2) {
  if (!co2) {
    return "0 kg";
  }
  // convert from g to kg
  const co2kg = co2 / 1000;
  // if co2 is smaller than 0.1 kg then return approximate 0 kg, as it is not exactly 0 kg 
  if (co2kg < 0.1) {
    return `~0 kg`;
  }
  return `${formatNumberToLocaleAnd2Fixed(co2kg)} kg`;
}

export function formatNumberToLocaleAnd2Fixed(num) {
  if (Number(num) === 0) {
    return num;
  }
  else if (num) {
    try {
      return Number(num).toFixed(2).replace('.', ',');
    } catch {
      return 0;
    }
  }
}
