import { defineStore } from "pinia";

export const usePollStore = defineStore({
  id: "poll",
  state: () => ({
    pollPlz: "0",
    title: "",
    externalPollLink: undefined,
    alternativeExternalPollLink: undefined,
    introductionSteps: [],
    introductionText: "",
    introductionTitle: "",
    pollDate: undefined,
    showCustomerLogo: false,
    pollMonthsInOrganization: undefined,
    pollWorkingDays: undefined,
    pollHomeofficeDays: undefined,
    pollMonthsInOrganizationMax: undefined,
    pollWorkingDaysMax: undefined,
    pollHomeofficeDaysMax: undefined,
    pollMonthsInUniversity: undefined,
    pollMonthsInUniversityMax: undefined,
    pollMonthsLecturePeriod: undefined,
    pollMonthsLecturePeriodMax: undefined,
    pollMonthsLectureFreePeriod: undefined,
    pollMonthsLectureFreePeriodMax: undefined,
    pollPresenceDaysInLecturePeriod: undefined,
    pollPresenceDaysInLecturePeriodMax: undefined,
    pollPresenceDaysInLectureFreePeriod: undefined,
    pollPresenceDaysInLectureFreePeriodMax: undefined,
    usePoll: false,
    frequencyType: 'days',
    pollType: 'company',
    frequencies: [],
    numberPassengers: [],
    numberStars: [],
    transportUsed: []
  }),
  getters: {
    pollFrequency: (state) => {
      return (transportId) => state.frequencies.find(f => f.transportId === transportId) ? state.frequencies.find(f => f.transportId === transportId).freq : 0
        ;
    },
    numberPassengersOfTransport: (state) => {
      return (transportId) => state.numberPassengers.find(f => f.transportId === transportId) ? state.numberPassengers.find(f => f.transportId === transportId).freq : 0;
    },
    isTransportUsed: (state) => {
      return (transportId) => state.transportUsed.find(f => f.transportId === transportId) ? state.transportUsed.find(f => f.transportId === transportId).used : false;
    },
    getNumberStars: (state) => {
      return (transportId) => state.numberStars.find(f => f.transportId === transportId) ? state.numberStars.find(f => f.transportId === transportId).freq : 0;
    }
  },
  actions: {
    /**
     * reset just the survey input related to transport evaluation and choices 
     * (but doesn't concern the user's personal info like postal code or the months he is in the organization)
     */
    resetSuveyInput() {
      this.frequencies = [];
      this.numberPassengers = [];
      this.numberStars = [];
      this.transportUsed = [];
    },
    isPollPlzValid() {
      if (this.pollPlz === '0' || (Number(this.pollPlz) && !Number.isNaN(this.pollPlz) && this.pollPlz.length === 5)) {
        return true;
      } else {
        return false;
      }
    },
    isPollMonthsInOrganizationValid() {
      if (
        this.pollMonthsInOrganization === undefined ||
        (Number.isInteger(Number(
          this.pollMonthsInOrganization)
        ) &&
          this.pollMonthsInOrganization <=
          this.pollMonthsInOrganizationMax
          && this.pollMonthsInOrganization >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isPollPresenceDaysInLectureFreePeriodValid() {
      if (
        this.pollPresenceDaysInLectureFreePeriod === undefined ||
        (Number.isInteger(Number(
          this.pollPresenceDaysInLectureFreePeriod)
        ) &&
          this.pollPresenceDaysInLectureFreePeriod <=
          this.pollPresenceDaysInLectureFreePeriodMax
          && this.pollPresenceDaysInLectureFreePeriod >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isPollPresenceDaysInLecturePeriodValid() {
      if (
        this.pollPresenceDaysInLecturePeriod === undefined ||
        (Number.isInteger(Number(
          this.pollPresenceDaysInLecturePeriod)
        ) &&
          this.pollPresenceDaysInLecturePeriod <=
          this.pollPresenceDaysInLecturePeriodMax
          && this.pollPresenceDaysInLecturePeriod >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isPollMonthsLectureFreePeriodValid() {
      if (
        this.pollMonthsLecturePeriod === undefined ||
        (Number.isInteger(Number(
          this.pollMonthsLecturePeriod)
        ) &&
          this.pollMonthsLecturePeriod <=
          this.pollMonthsLecturePeriodMax
          && this.pollMonthsLecturePeriod >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isPollMonthsLecturePeriodValid() {
      if (
        this.pollMonthsLecturePeriod === undefined ||
        (Number.isInteger(Number(
          this.pollMonthsLecturePeriod)
        ) &&
          this.pollMonthsLecturePeriod <=
          this.pollMonthsLecturePeriodMax
          && this.pollMonthsLecturePeriod >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isPollMonthsInUniversityValid() {
      if (
        this.pollMonthsInUniversity === undefined ||
        (Number.isInteger(Number(
          this.pollMonthsInUniversity)
        ) &&
          this.pollMonthsInUniversity <=
          this.pollMonthsInUniversityMax
          && this.pollMonthsInUniversity >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    /*
    checking frequencies - depending on type percentage/year or days/week
    */
    isPollFrequenciesValid() {
      // event-type doesn't use frequencies
      if (this.pollType === 'event') {
        return true;
      }

      if (this.frequencies.length === 0) {
        return false;
      }
      else if (this.frequencyType === 'percentage') {
        const sum = this.frequencies.map((a) => a.freq).reduce((a, b) => a + b);

        return sum === 100;
      } else if (this.frequencyType === 'days') {
        const sum = this.frequencies.map((a) => a.freq).reduce((a, b) => a + b);
        const nDays = this.pollWorkingDays !== undefined ? this.pollWorkingDays - this.pollHomeofficeDays : this.pollPresenceDaysInLecturePeriod;
        return sum === Number(nDays);
      } else {
        return false;
      }
    },
    isTransportsUsedValid() {
      if (this.pollType === 'event') {
        const sum = this.transportUsed.filter(m => m === true);
        return sum.length === 1 ? true : false;


      } else if (this.pollType === 'company') {
        return true;
      }
    },
    isPollWorkingDaysValid() {
      if (
        this.pollWorkingDays === undefined ||
        (Number.isInteger(Number(
          this.pollWorkingDays)
        ) &&
          this.pollWorkingDays <=
          this.pollWorkingDaysMax
          && this.pollWorkingDays >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isPollHomeofficeDaysValid() {
      if (
        this.pollHomeofficeDays === undefined ||
        (Number.isInteger(Number(
          this.pollHomeofficeDays)
        ) &&
          this.pollHomeofficeDays <=
          this.pollHomeofficeDaysMax
          && this.pollHomeofficeDays >= 0
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
    * returns validity of pollData
    */
    isPollValid() {
      // if the general settings are valid and 
      if (
        this.isPollHomeofficeDaysValid() &&
        this.isPollMonthsInOrganizationValid() &&
        this.isPollWorkingDaysValid() && this.isPollPlzValid()
        && this.isPollFrequenciesValid()
        && this.isTransportsUsedValid()
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
* returns validity of pollData without considering the routes choices of user, as it will be done on the alternative external poll page
*/
    isAlternativePollValid() {
      // if the general settings are valid and 
      if (
        this.isPollHomeofficeDaysValid() &&
        this.isPollMonthsInOrganizationValid() &&
        this.isPollWorkingDaysValid() && this.isPollPlzValid()
      ) {
        return true;
      } else {
        return false;
      }
    },
    setGeneralPollSettings(generalSettings) {
      if (generalSettings) {
        this.pollMonthsInOrganization = generalSettings.pollMonthsInOrganization;
        this.pollWorkingDays = generalSettings.pollWorkingDays;
        this.pollHomeofficeDays = generalSettings.pollHomeofficeDays;
        this.pollMonthsInOrganizationMax = generalSettings.pollMonthsInOrganizationMax;
        this.pollWorkingDaysMax = generalSettings.pollWorkingDaysMax;
        this.pollHomeofficeDaysMax = generalSettings.pollHomeofficeDaysMax;
        this.pollMonthsInUniversity = generalSettings.pollMonthsInUniversity;
        this.pollMonthsInUniversityMax = generalSettings.pollMonthsInUniversityMax;
        this.pollMonthsLecturePeriod = generalSettings.pollMonthsLecturePeriod;
        this.pollMonthsLecturePeriodMax = generalSettings.pollMonthsLecturePeriodMax;
        this.pollMonthsLectureFreePeriod = generalSettings.pollMonthsLectureFreePeriod;
        this.pollMonthsLectureFreePeriodMax = generalSettings.pollMonthsLectureFreePeriodMax;
        this.pollPresenceDaysInLecturePeriod = generalSettings.pollPresenceDaysInLecturePeriod;
        this.pollPresenceDaysInLecturePeriodMax = generalSettings.pollPresenceDaysInLecturePeriodMax;
        this.pollMonthsLectureFreePeriodMax = generalSettings.pollMonthsLectureFreePeriodMax;
        this.pollPresenceDaysInLectureFreePeriod = generalSettings.pollPresenceDaysInLectureFreePeriod;
        this.pollPresenceDaysInLectureFreePeriodMax = generalSettings.pollPresenceDaysInLectureFreePeriodMax;

      }
    },
    /***
     * create date object from a date string
     */
    setPollDate(val) {
      if (!val) {
        return;
      }
      const dateParts = val.split(".");

      let month, day, year;
      if (dateParts.length === 3) {
        day = Number(dateParts[0]);
        month = Number(dateParts[1]);
        year = Number(dateParts[2]);
      }
      let dateBuilder = new Date();
      dateBuilder.setFullYear(year);
      dateBuilder.setMonth(month - 1);
      dateBuilder.setDate(day);
      this.pollDate = dateBuilder;
    },
    setUsePoll(val) {
      this.usePoll = val;
    },
    setFrequencyType(val) {
      this.frequencyType = val;
    },
    setClientName(val) {
      this.clientName = val;
    },
    setPollType(val) {
      this.pollType = val;
    },
    setTitle(val) {
      this.title = val;
    },
    setExternalPollLink(val) {
      this.externalPollLink = val;
    },
    setUseExternalPollLink(val) {
      this.useExternalPollLink = val;
    },
    setAlternativeExternalPollLink(val) {
      this.alternativeExternalPollLink = val;
    },
    setUseAlternativeExternalPollLink(val) {
      this.useAlternativeExternalPollLink = val;
    },
    setIntroductionSteps(val) {
      this.introductionSteps = val;
    },
    setIntroductionText(val) {
      this.introductionText = val;
    },
    setIntroductionTitle(val) {
      this.introductionTitle = val;
    },
    setShowCustomerLogo(val) {
      this.showCustomerLogo = val;
    },
    setPollWorkingDays(val) {
      this.pollWorkingDays = val;
    },
    setPollHomeofficeDays(val) {
      this.pollHomeofficeDays = val;
    },
    setPollMonthsInOrganization(val) {
      this.pollMonthsInOrganization = val;
    },
    setPlz(plzStr) {
      this.pollPlz = plzStr;
    },
    /**
     * 
     * @param {*} obj contains freq (=frequency) and transportId 
     */
    addFrequency(obj) {
      this.frequencies.push(obj);
    },
    addNumberStars(obj) {
      this.numberStars.push(obj)
    },
    setNumberStars(num, transportId) {
      let numStars = this.numberStars.find(s => s.transportId === transportId);
      numStars.freq = num;
    },
    resetFrequencies() {
      this.frequencies = [];
    },
    setFrequency(num, transportId) {
      let freq = this.frequencies.find(f => f.transportId === transportId);
      freq.freq = num;
    },
    incrementFrequency(transportId) {
      let freq = this.frequencies.find(f => f.transportId === transportId)
      freq.freq += 1;
    },
    decrementFrequency(transportId) {
      let freq = this.frequencies.find(f => f.transportId === transportId)

      if (freq.freq > 0) {
        freq.freq -= 1;
      }
    },
    /**
     * 
     * @param {*} obj contains freq (=frequency) and transportId 
     */
    addNumberPassengers(obj) {
      this.numberPassengers.push(obj);
    },
    resetNumberPassengers() {
      this.numberPassengers = [];
    },
    setNumberPassengers(num, transportId) {
      let numPass = this.numberPassengers.find(p => p.transportId === transportId);
      numPass.freq = num;
    },
    incrementNumberPassengers(transportId) {
      let numPass = this.numberPassengers.find(p => p.transportId === transportId);
      numPass.freq += 1;
    },
    decrementNumberPassengers(transportId) {
      let numPass = this.numberPassengers.find(p => p.transportId === transportId);
      if (numPass.freq > 0) {
        numPass.freq -= 1;
      }
    },
    addTransportUsed(obj) {
      this.transportUsed.push(obj);
    },
    resetTransportUsed() {
      this.transportUsed = [];
    },
    setTransportUsed(val, transportId) {
      let tUsed = this.transportUsed.find(t => t.transportId === transportId);
      if (tUsed) {
        tUsed.used = !tUsed.used;
      }

    },
  },
});
